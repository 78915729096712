@import '/src/styles/variables.scss';

.standard-checkbox {
  .option {
    background: #fff;
    min-height: 48px;
    margin-top: 0.5rem;
    border-radius: 10px;
    border: 1px solid #dedede;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    &.disabled,
    &.disabled * {
      cursor: not-allowed;
    }
    input {
      margin-top: 1rem;
    }
    &.active {
      background: #f5f5f5;
    }
    label {
      margin-top: 0.5rem;
    }
  }
}
