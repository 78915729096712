@import "../styles/variables.scss";

.custom-progress {
  animation-name: custom_animation !important;
  @keyframes custom_animation {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
}

.cs-toast-success{
  .Toastify__toast-icon{
    svg {
      fill: $base-success !important; 
    }
  }
  .Toastify__progress-bar--success{
    background: $base-success !important;
  }
}
