@import "../../../../styles/variables.scss";

#action-panel {
  display: flex;
  // padding: 0px 16px 8px 16px;
  // justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  background: #fff;

  .set-up-button {
    &.pulse {
      animation: animate 3s linear infinite;
    }

    // &.pulse i {
    //     position: relative;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: 30px;
    //     color: #fff;
    //     height: 100%;
    //     cursor: pointer
    // }

    @keyframes animate {
      0% {
        box-shadow:
          0 0 0 0 rgb(127, 17, 214, 0.7),
          0 0 0 0 rgb(127, 17, 214, 0.7);
      }

      40% {
        box-shadow:
          0 0 0 20px rgb(127, 17, 214, 0),
          0 0 0 0 rgb(127, 17, 214, 0.7);
      }

      80% {
        box-shadow:
          0 0 0 20px rgb(127, 17, 214, 0),
          0 0 0 20px rgb(127, 17, 214, 0);
      }

      100% {
        box-shadow:
          0 0 0 0 rgb(127, 17, 214, 0),
          0 0 0 20px rgb(127, 17, 214, 0);
      }
    }
  }
}

.actions-div {
  display: flex;
  padding: 0px 16px 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.action-panel-text-div {
  // min-width: 220px;
  width: 175px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 8px 16px;
  justify-content: center;

  // align-self: stretch;
}

#action-div {
  // min-width: 200px;
  width: 168px !important;
  height: 122px !important;
  display: flex;
  padding: 8px 16px !important;
  flex-direction: column;
  justify-content: center;
  // justify-content: space-between;
  align-items: center;
  // gap: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.action-panel-card-div {
  margin: 0px 12px;
}
