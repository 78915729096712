@import '/src/styles/variables.scss';
.edit-respondants-popup {
  z-index: 9999 !important;
  .user-cards-container {
    overflow-x: hidden;
  }
  .cs-heading {
    color: $primary;
    font-weight: bold;
  }

  .cs-sub-heading {
    color: $base-border;
    font-size: 16px;
  }
  .user-card {
    .card-header {
      color: $primary;
      font-weight: bold;
      text-align: center;
      padding: 1rem;

      .respondant-container {
        // padding: 1rem;
        height: 49px;
        width: 49px;
        background-color: #cbeaff;
        display: inline-block;
        border-radius: 50%;
        margin-right: 1rem;
        img {
          height: 25px;
          margin-top: 12px;
        }
      }
    }
    .option {
      min-height: 40px;
      border: 0px;
      border-bottom: 1px solid #dedede;
      border-radius: 0px;
      color: #a6afc5;
      background: transparent;
      &.active {
        color: black;
        background: transparent;
      }
      .checkmark {
        height: 20px;
        width: 20px;
        &::after {
          left: 6px;
          top: 2px;
        }
      }
      label {
        font-size: 14px;
      }
      &.disabled {
        color: $base-hint;
        .checkmark {
          border-color: $base-hint;
        }
        .checkmark:after {
          border-color: $base-hint;
        }
      }
    }
  }
  .modal-header,
  .modal-footer {
    border: 0px;
  }
  .standard-button {
    min-width: 120px;
  }
  .loading-text {
    color: $base-hint;
    font-weight: 400;
  }
}
