@import '/src/styles/variables.scss';

.standard-pagination {
  .page-item {
    .page-link {
      color: $base-success;
    }
    &.active {
      .page-link {
        color: white;
        background-color: $base-success;
        border-color: $base-success;
      }
    }
  }
  .entries-text {
    color: $base-border;
  }
}
