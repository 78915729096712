@import "./variables.scss";
@import "./checkbox.scss";
@import "./radio.scss";
html,
body,
#root {
  height: 100%;
  font-family: "Noto Sans", sans-serif !important;
}

.vertical-separator {
  width: 1px !important;
  height: 24px !important;
  background: #f6f6f6 !important;
  &-stretched {
    width: 1px !important;
    background: #f6f6f6 !important;
    align-self: stretch;
  }
}

.cs-mb-28 {
  margin-bottom: 1.75rem !important;
}


.cs-heading {
  font-size: 24px;
  font-weight: 1000;
  line-height: 1.3;
}

.cs-sub-heading {
  font-size: 20px;
  font-weight: 700;
}

.cs-content {
  font-size: 16px;
}

.hint {
  color: $base-hint;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  margin-top: 12px;
}

.link-alike {
  color: $base-success !important;
  font-weight: 800;
}

.title-font-size{
  font-size: 18px;
}

.content-font-size{
  font-size: 16px;
}

.sub-content-font-size{
  font-size: 14px;
}

.font-weight-bold {
  font-weight: 1000 !important;
}

.font-weight-normal {
  font-weight: 500 !important;
}

.hr-like {
  background-color: $primary !important;
  height: 5px !important;
  width: 60px;
  margin-top: 12px;
}

.error-text {
  color: $base-danger;
}
.error-text:first-letter {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
.card-footer {
  background-color: #fff !important;
}

.status-pill {
  margin: 0 0 0 16px;
  @media screen and (max-width: $screen-sm-min) {
    margin: 0 8px 0 0;
  }
  padding: 8px 12px;
  border-radius: 20px;
  display: inline-block;
  &.green {
    background: #e7ffed;
    color: #6ccb83;
  }
  &.blue {
    background: #e7e7ff;
    color: #6c6ecb;
  }
  &.red {
    background: #fff7ee;
    color: #ff8a34;
  }
  &.dark-red {
    background: rgb(255, 0, 0, 0.6);
    color: #fff;
  }
  &.grey {
    background: #f5f5f5;
    color: $base-hint;
  }
  &.dark-magenta {
    background-color: #ff2894;
    color: #fff;
  }
  &.yellow {
    background: rgba(255, 174, 0, 0.09);
    color: #eba000;
  }
}

.expand-btn {
  min-width: 100px !important;
  width: 140px;
  height: 40px;
  padding-top: 8px;
  border-width: 2px;
}
.text-primary-new{
  color: $base-success !important;
  }

.blink {
  animation: blink 1.33s ease-out infinite;
  &:hover {
    animation: none;
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.jiggle {
  animation: jello 2s forwards;
  @keyframes jello {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
}

.toggler {
  display: flex;
  align-items: center;
  .standard-button {
    flex: 1 1 auto !important;
    border: 1px solid $base-hint;
    border-right: 0px;
    color: $base-hint;
    text-align: center;
    height: 40px;
    padding: 12px !important;
    // min-width: 150px;
    &.active {
      color: #fff;
      background: $base-success;
    }
  }
  .standard-button:first-child {
    border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .standard-button:last-child {
    border-right: 1px solid $base-hint;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  :not(.standard-button:first-child, .standard-button:last-child) {
    border-radius: 0;
  }
}

// DISCIPLINES COLORS UTILS
.bg-strategy {
  background-color: $strategy-color !important;
}
.bg-execution {
  background-color: $execution-color !important;
}
.bg-people {
  background-color: $people-color !important;
}
.bg-board {
  background-color: $board-color !important;
}
.bg-sales {
  background-color: $sales-color !important;
}
.bg-operations {
  background-color: $operations-color !important;
}
.bg-finance {
  background-color: $finance-color !important;
}
.bg-capital {
  background-color: $capital-color !important;
}
.alt-strategy {
  color: $strategy-color !important;
}
.alt-execution {
  color: $execution-color !important;
}
.alt-people {
  color: $people-color !important;
}
.alt-board {
  color: $board-color !important;
}
.alt-sales {
  color: $sales-color !important;
}
.alt-operations {
  color: $operations-color !important;
}
.alt-finance {
  color: $finance-color !important;
}
.alt-capital {
  color: $capital-color !important;
}

.do-later {
  color: $base-hint;
  text-decoration: none;
  &:hover {
    color: $primary;
  }
}

.terms-conditions {
  color: #7b88a5;
  a {
    color: $primary;
    cursor: pointer;
    font-weight: 600;
  }
}

.main-logo {
  max-width: 80%;
  height: auto;
}
.auth-side-logo {
  max-width: 50%;
  height: auto;
}

@media (min-width: 991.98px) {
  .h-md-100 {
    height: 100%;
  }
}

.resource-content, .chapter-content, .recommendation-pretext, .course-overview {
  .image {
    img {
      width: 100%;      
    }
  }
  .image{    
    text-align: center;
  }
  .image-style-align-right{
    text-align: right;
  }
  .image-style-align-left{
    text-align: left;
  }
  .image_resized{
    img{
      width: 100%;
    }
    &.image{    
      margin: auto;
    }
    &.image-style-align-right{
      margin: 0 0 0 auto;
    }
    &.image-style-align-left{
      margin : 0 0 0 0;
    }
  }
  padding-top: 1rem;
  p {
    text-align: justify;
  }
  ul {
    text-align: justify;
    margin-left: 1.5rem;
  }
  ul li {
    margin-bottom: 1rem;
  }

  ul li::marker {
    color: $primary; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
  }

  blockquote {
    background-color: #f7f7f7;
    padding: 1rem;
    font-family: Quicksand;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2a2b2b;
  }
  figure {
    img {
      // width: 100%;
    }
  }
}
