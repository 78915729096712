$base-banner-text: #fff6f6;

$base-success: #08B3C3;
$base-primary-dark: #1d3d92;
$base-primary: #056C75;
$base-danger: #f23e3e;
$base-dark: #2b2a2a;
$base-light: #b5cded;
$base-secondary: #c3bbad;
$base-background: #f2f2f2;
$base-label: #011542;
$base-grey: #eef0f4;
$primary-color-new: #08B3C3;
$background-grey: #f9f9f9;
$base-border: #7b88a5;
$base-hint: #a6afc5;
$light-grey: #e6e6e6;
$border-grey: #e6e4e5;
$light-grey-new: #656565;
$dark-grey: #333;
$primary: $base-primary;

$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;
$screen-xxl-min: 1399px;
$screen-xxl-max: 1400px;

$overall-color: #1eb8a5;
$overall-sub-color: #79d3c8;
$overall-sub-sub-color: #93d6cd;
$strategy-color: #3667e3;
$strategy-sub-color: #7d9ae4;
$strategy-sub-sub-color: #acbeec;
$execution-color: #6336e3;
$execution-sub-color: #987de4;
$execution-sub-sub-color: #bfb1e7;
$people-color: #4cb857;
$people-sub-color: #7dcb85;
$people-sub-sub-color: #a5d3a9;
$board-color: #d9596a;
$board-sub-color: #fc92a0;
$board-sub-sub-color: #f0b2bb;
$sales-color: #e88f4f;
$sales-sub-color: #e7a06d;
$sales-sub-sub-color: #edbd99;
$operations-color: #1ba4b7;
$operations-sub-color: #7dc0c9;
$operations-sub-sub-color: #a5d3da;
$finance-color: #a3bb41;
$finance-sub-color: #b6c675;
$finance-sub-sub-color: #c7d19c;
$capital-color: #924cc9;
$capital-sub-color: #ba85e3;
$capital-sub-sub-color: #cfb0e7;