@import '/src/styles/variables.scss';

.line-progress {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 8px;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #e7e7e7;
  width: 320px;
  line-height: 0.75;
  .bar {
    width: 200px;
  }
  .progress-bar {
    background-color: $base-success;
    height: 0.5rem;
  }
  .progress {
    height: 0.5rem;
  }
  .text {
    width: 120px;
    font-size: 14px;
    font-weight: 1000;
    color: $base-border;
    margin-left: 20px;
    .current {
      color: $primary;
    }
  }
}
