@import '/src/styles/variables.scss';

$button-hover-alpha: 0.3;
$button-focus-darken: 15; // percentage

@mixin make-button($bgcolor, $color, $name) {
  &.btn-#{$name} {
    font-weight: 700;
    color: $color;
    background-color: $bgcolor;
    border-color: $bgcolor;

    &:disabled,
    &.disabled {
      border-color: $bgcolor;
      background-color: $bgcolor;
    }
  }

  &:not(:disabled):not(.disabled),
  :not(:disabled):not(.disabled) {
    &.btn-#{$name} {
      &:hover {
        color: $bgcolor;
        background-color: rgba($bgcolor, $button-hover-alpha);
        border-color: $bgcolor;
      }

      &:active {
        color: #fff;
        background-color: $bgcolor;
        border-color: $bgcolor;
      }

      &:focus {
        color: #fff;
        background-color: darken($color: $bgcolor,
            $amount: $button-focus-darken );
        border-color: darken($color: $bgcolor, $amount: $button-focus-darken);
        box-shadow: none !important;
      }
    }
  }
}

@mixin make-outline-button($color, $name) {
  &.btn-outline-#{$name} {
    font-weight: 700;
    color: $color;
    border-color: $color;

    &:disabled,
    &.disabled {
      color: $color;
      border-color: $color;
    }
  }

  &:not(:disabled):not(.disabled),
  :not(:disabled):not(.disabled) {
    &.btn-outline-#{$name} {
      &:hover {
        color: $color;
        background-color: rgba($color, $button-hover-alpha);
        border-color: $color;
      }

      &:active {
        color: #fff;
        background-color: rgba($color, $button-hover-alpha);
        border-color: $color;
      }

      &:focus {
        $darken-color: darken($color: $color,
            $amount: $button-focus-darken,
          );
        color: #fff;
        background-color: rgba($color: $darken-color,
            $alpha: $button-hover-alpha );
        border-color: darken($color: $color, $amount: $button-focus-darken);
        box-shadow: none !important;
      }
    }
  }
}

.standard-button {
  $button-hover-alpha: 0.3;

  i,
  p {
    display: inline-block;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.btn-block {
    width: 100% !important;
  }

  &.btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    line-height: 1;

    &.btn-sm {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  &.btn-breakdown {
    background-color: white;
    border: 1px solid $border-grey;

    &:hover {
      background-color: $border-grey;
    }
  }

  &.border-less {
    border: none;
  }

  @include make-button($primary, #fff, 'primary');
  @include make-button(#000, #fff, 'black');
  @include make-button($base-grey, #000, 'grey');
  @include make-button($base-success, #fff, 'success');
  @include make-button($base-secondary, $base-secondary, 'secondary');

  @include make-button(grey, $base-background, 'disabled');

  &.btn-disabled {
    cursor: default !important;
  }

  @include make-outline-button($primary, 'primary');
  @include make-outline-button($base-danger, 'danger');
  @include make-outline-button($base-border, 'dark');
  @include make-outline-button($base-secondary, 'secondary');
  @include make-outline-button($base-success, 'success');
  @include make-outline-button(#878B9D, 'disabled');
}