@import '/src/styles/variables.scss';

.edit-recomm-modal {
  // react-beautiful-dnd does not support nested scroll container
  overflow: hidden !important;
  z-index: 9999 !important;
}
.edit-recomm-popup {
  max-width: 90vw !important;
  overflow: hidden;
  @media screen and (max-width: $screen-md-min) {
    max-width: 100vw !important;
  }
  .recoms-list {
    overflow: auto;
    height: 65vh !important;
  }
  .recommendations-card {
    user-select: none;
    &.dragging {
      .card-header {
        background-color: rgba($base-secondary, 0.5) !important;
        transition: all 100ms ease-in-out;
      }
    }
    .card-header {
      border-bottom: none;
      padding: 12px;
      .cs-sub-heading {
        font-size: 1.2rem;
        margin-bottom: 0.5rem !important;
      }
      .discipline-category {
        font-size: 0.8rem;
        padding: 0.35rem 0.5rem;
      }
    }
  }
  .discipline-list {
    background: #f7f7f7;
    padding: 16px 0;
    color: $base-hint;
    // min-width: 200px;
    .unread-circle {
      width: 5px;
      height: 5px;
      background-color: $primary;
      border-radius: 50%;
    }
    & > .active {
      color: $primary;
      font-weight: 800;
      border-left: 4px solid $primary;
      .img-container::before {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        content: '';
        border-bottom: 5px solid $primary;
        transform: rotate(90deg);
        margin-top: 10px;
        // margin-right: 26.5%;
        float: left;
        margin-left: -35px;
      }
    }
    & > div {
      padding: 8px 16px;
      border: 4px solid #f7f7f7;
      cursor: pointer;
    }
  }
  .recom-number-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
  .recom-title {
    .cs-heading {
      color: $primary;
      font-weight: bold;
    }
    .cs-sub-heading {
      font-size: 16px;
      color: $base-hint;
      font-weight: normal;
    }
  }
  .reorder-text {
    color: $base-hint;
  }
  .reset-button {
    color: $primary;
    background-color: #f7f7f7;
    &:hover {
      background-color: #f0f0f0;
      color: $primary;
    }
    &:active {
      color: $primary;
      background-color: #fff;
      border-color: #e7e7e7;
    }
    &:focus {
      color: $primary;
      box-shadow: 0 0 0 0.15rem rgba($base-dark, 0.3);
    }
  }
  .modal-header {
    border: 0;
  }
  .recom-builder-breadcrumbs {
    display: flex;
    justify-content: start;
    align-items: center;
    background: #f7f7f7;
    padding: 1rem;
    .breadcrumb-item {
      display: inline-flex;
      align-items: center;
      position: relative;
      margin-right: 0.5rem;
      color: $base-hint;
      font-weight: 600;
      $step-dims: 1.6rem;
      .step-number {
        background-color: $base-hint;
        color: #fff;
        font-size: 16px;
        border-radius: 50%;
        height: $step-dims;
        width: $step-dims;
        text-align: center;
        line-height: $step-dims;
      }
      &:not(:first-of-type) {
        &::before {
          content: '';
          margin-right: 1rem;
          width: 7rem;
          height: 2px;
          position: relative;
          background-color: $base-hint;
        }
      }
      &.active {
        color: $primary;
        .step-number {
          background-color: $primary;
        }
        &:not(:first-of-type) {
          &::before {
            background-color: $primary;
          }
        }
      }
    }
  }
}
