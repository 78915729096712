@import "../../../../styles/variables.scss";

.resource-details-container {
  max-width: $screen-lg-min;
  .card-header {
    color: white;
    background-color: #666666;
    padding: 1.5rem 1rem 1.5rem 1rem;
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    .resource-discipline {
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .resource-type-pill {
      position: absolute;
      bottom: 15px;
      right: 15px;
      background-color: white;
      color: $base-hint;
      border-radius: 100px;
      padding: 0.5rem;
      font-size: 0.8rem;
    }
  } 
  .card-footer {
    border: none;
  }
}
