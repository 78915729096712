@import '/src/styles/variables.scss';

.publish-results-popup {
  .modal-header {
    border: 0;
    .modal-title {
      font-weight: 600;
    }
  }
  p {
    color: $base-border;
    .contact-name {
      font-weight: 600;
      color: $primary;
    }
    .contact-company {
      color: $primary;
    }
  }
  .publish-results-image {
    position: relative;
    img:last-child {
      position: absolute;
      bottom: -11%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .standard-button {
    flex: 1;
  }
}
