@import '/src/styles/variables.scss';
.xray {
  ul {
    padding-left: 0;
  }
  .tab-logo {
    padding: 10px;
    border-radius: 50%;
    background: $background-grey;
  }
  .tab-title {
    color: $base-border;
    font-weight: 600;
  }
  .tab-desc {
    color: $base-border;
  }
  .react-tabs__tab--selected {
    border: none;
    border-bottom: 3px solid $base-success;

    .tab-logo {
      background: $background-grey;
    }
    .tab-title {
      color: $base-label;
    }
  }
  .bg-grey {
    background: #f7f7f7;
  }

  .bg-grey-dark {
    background: #efefef;
  }
  .bg-grey-superdark {
    background: #e5e5e5;
  }
  .react-tabs__tab {
    width: 33%;
  }
}
