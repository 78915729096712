@import '/src/styles/variables.scss';

.confirmation-popup {
  .modal-header {
    border: 0;
    .modal-title {
      font-family: 'Noto Sans', sans-serif !important;
      font-weight: 600;
    }
  }
}
