@import '/src/styles/variables.scss';

.no-data-card {
  border-radius: 5px;
  .title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .content {
    font-size: 1rem;
  }
}
