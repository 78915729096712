@import '/src/styles/variables.scss';
.course-detail-card {
  .card-header {
    padding: 0;
    border-bottom: none;
    height: 350px;
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    button:hover {
      background-color: #fff !important;
    }
  }
  .card-body {
    .title {
      color: $base-success;
    }
    .info {
      border-top: 1px solid #dbdcde;
      padding: 1rem 0;
      margin-top: 1rem;
      .author {
        color: $base-border;
        font-size: 14px;
        .icon-in-btn {
          color: $base-hint;
          top: 6px;
        }
      }
      .category {
        color: #fff;
        font-weight: 800;
        padding: 4px 12px;
        border-radius: 18px;
      }
    }
    .sections {
      border: 1px solid #dbdcde;
      padding: 1rem;
      border-radius: 5px;
      .section {
        background-color: #f7f7f7;
        padding: 0.75rem;
        margin-top: 1rem;
        border-radius: 5px;
        cursor: pointer;
        &.active {
          color: $base-success;
          .standard-button {
            color: $base-success !important;
            border-color: $base-success;
          }
        }
        .progress-icon {
          height: 24px;
          .material-icons {
            font-size: 36px;
          }
          &.blank {
            color: $base-hint;
          }
          &.completed {
            color: $base-success !important;
          }
          &.current {
            color: $base-success !important;
          }
        }
      }
    }
    .overview {
      white-space: pre-line;
    }

    .chapter-container {
      border-top: 1px solid #dbdcde;
      background-color: #f7f7f7;
      padding: 0 0.75rem;
      a {
        text-decoration: none;
        color: $base-label;
      }
      .chapter {
        margin: 0 1rem;
        border-bottom: 1px solid #dbdcde;
        padding: 0.75rem 0;
        &.active {
          color: $light-grey-new;
        }
        &:last-child {
          border-bottom: none;
        }
        img {
          height: 20px;
          width: 20px;
        }
        .progress-icon {
          height: 18px;

          .material-icons {
            font-size: 24px;
          }
          &.blank {
            color: $base-hint;
          }
          &.completed {
            color: $base-success;
          }
          &.current {
            color: $base-success;
          }
        }
      }
    }
  }
  .progress-container {
    border-top: none;
    padding-top: 1rem;
    padding-bottom: 0px;
    padding-left: 0px;
    a {
      text-decoration: none !important;
    }
    .standard-button {
      height: 45px;
      padding: 12px;
      .icon-in-btn {
        margin-top: -6px;
        top: 7px;
      }
    }
    .completed {
      height: 45px;
      background-color: #f3f3f3;
      color: $primary;
      font-weight: bold;
      cursor: pointer;
    }
    .line-progress {
      max-width: 320px;
      height: 45px;
      background-color: #f3f3f3;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      margin-top: 0px !important;
      .bar {
        width: 60%;
      }
      .text {
        width: 25%;
        display: inline-block;
        font-size: 12px;
        color: $base-hint;
        font-weight: 400;
      }
      .percentage {
        color: $primary;
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 0.25rem;
      }
    }
  }
}
