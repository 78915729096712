@import '/src/styles/variables.scss';

.contacts {
  .search-input-container {
    max-width: 350px;
    .after-icon-button {
      border: none;
      background: transparent;
      padding: 0;
    }
  }
  .search-input {
    background: transparent;
    &:active,
    &:focus,
    &:hover {
      background: transparent;
    }
  }
}
#sort-dropdown {
  background: transparent;
  border: none;
  color: $base-success !important;
  font-size: 16px;
  &:focus {
    box-shadow: none;
  }
}
.contact-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  li {
    margin-bottom: 1rem;
    .contact-card {
      box-shadow: none !important;
      .field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .label {
          color: lightslategrey;
        }
        .value {
          display: flex;
          justify-content: start;
          align-items: center;
          font-weight: 600;
          .material-icons {
            color: $base-success;
          }
        }
      }
    }
  }
}
