@import '/src/styles/variables.scss';

$border-color: #dbdcde;

@mixin discipline-category-bg($dcolor, $sdcolor, $ssdcolor, $color: #fff) {
  &.discipline {
    background-color: $background-grey;
    color: $light-grey-new;
  }

  &.sub-discipline {
    background-color: $background-grey;
    color: $light-grey-new;
  }

  &.sub-sub-discipline {
    background-color: $background-grey;
    color: $light-grey-new;
  }
}

.discipline-category {
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  width: fit-content;

  &.strategy {
    @include discipline-category-bg($strategy-color,
      $strategy-sub-color,
      $strategy-sub-sub-color );
  }

  &.execution {
    @include discipline-category-bg($execution-color,
      $execution-sub-color,
      $execution-sub-sub-color );
  }

  &.people {
    @include discipline-category-bg($people-color,
      $people-sub-color,
      $people-sub-sub-color );
  }

  &.board {
    @include discipline-category-bg($board-color,
      $board-sub-color,
      $board-sub-sub-color );
  }

  &.sales {
    @include discipline-category-bg($sales-color,
      $sales-sub-color,
      $sales-sub-sub-color );
  }

  &.operations {
    @include discipline-category-bg($operations-color,
      $operations-sub-color,
      $operations-sub-sub-color );
  }

  &.finance {
    @include discipline-category-bg($finance-color,
      $finance-sub-color,
      $finance-sub-sub-color );
  }

  &.capital {
    @include discipline-category-bg($capital-color,
      $capital-sub-color,
      $capital-sub-sub-color );
  }
}

.recom-number {
  background: $base-success;
  color: #fff;
  font-size: 18px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  padding-top: 2px;

  &.coach-number {
    background-color: $base-hint;
  }
}

.recommendations-card {
  .card-header {
    background: #fff;
    border-bottom: 1px solid $border-color;
    padding: 16px;
    position: relative;

    &.recomm-hidden {
      opacity: 0.3;
    }

    .coach-note-btn {
      width: 40px;
    }

    .crisis-flag {
      color: $base-danger;
      position: relative;
      top: 4px;
    }
  }
}