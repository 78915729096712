@import '/src/styles/variables.scss';

.stats-card {
  .xray-nav {
    border-bottom: 1px solid $light-grey;

    .icon {
      color: $base-success;
      top: 2px;
      position: relative;
    }

    .xray-nav-btn-icon {
      background-color: #fff;
      color: #d9596a;
      border-radius: 50%;
      width: 1.35rem;
      height: 1.35rem;
      padding-top: 3px;
    }

    .xray-company-name {
      margin-bottom: 0;
      color: $base-success;
      font-weight: 600;
      font-family: inherit;
      font-size: 1.25rem;
    }
  }

  .discipline-list {
    background: #f7f7f7;
    padding: 16px 0;
    color: $base-hint;

    &>.active {
      color: $base-success;
      font-weight: 800;
      border-left: 4px solid $base-success;

      .img-container::before {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        content: '';
        border-bottom: 5px solid $base-success;
        transform: rotate(90deg);
        margin-top: 10px;
        // margin-right: 26.5%;
        float: left;
        margin-left: -19px;
      }
    }

    &>div {
      padding: 8px 16px;
      border: 4px solid #f7f7f7;
      cursor: pointer;
    }
  }

  .bmi-box {
    border-radius: 5px;
    border: 1px solid #ebebeb;
    height: 300px;
    padding: 16px;

    .speedometer {
      height: 200px !important;

      .segment-value {
        fill: grey !important;
      }
    }
  }

  // .charts-container {
  //   overflow: auto;
  //   width: 100% !important;
  //   @media screen and (max-width: 1200px) {
  //     width: 1201px !important;
  //   }
  // }
  .recharts-polygon {
    &[name='Required Score'] {
      stroke-dasharray: 3, 3;
    }
  }

  .legend {
    height: 10%;

    .required {
      height: 100%;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      border-left: 1px solid #ebebeb;
      border-radius: 5px;
      padding-left: 1rem;

      .indicator {
        margin: 1rem;
        margin-top: 16px;
        height: 0px;
        width: 30px;
        border-top: 1px dotted grey;
      }
    }

    .gut {
      height: 100%;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;

      .indicator {
        margin: 0 1rem;
        height: 20px;
        width: 30px;
        background-color: rgb(45, 74, 163, 0.4);
        border: 1px solid #2d4aa3;
      }
    }

    .actual {
      height: 100%;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      padding-right: 1rem;
      border-radius: 5px;

      .indicator {
        margin: 0 1rem;
        height: 20px;
        width: 30px;
        background-color: rgba($color: #056C75, $alpha: 0.4);
        border: 1px solid $primary;
      }
    }
  }
}