@import "../../../styles/variables.scss";

.quest-container{
  overflow: auto;
}
.empty-stat-text {
  color: #404040 !important;
}

.quest-primary-color {
  color: #08B3C3;
}

.quest-btn-light-bg {
  background-color: #f4f4f4;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

#quest-winning-team-score {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  > div {
    width: 135px !important;
  }
}
.quest-nav {
  border-bottom: 1px solid $light-grey;
  .icon {
    color: $base-success;
    top: 2px;
    position: relative;
  }
  .quest-nav-btn-icon {
    background-color: #fff;
    color: #d9596a;
    border-radius: 50%;
    width: 1.35rem;
    height: 1.35rem;
    padding-top: 3px;
  }
  .quest-company-name {
    margin-bottom: 0;
    color: $base-success;
    font-weight: 600;
    font-family: inherit;
    font-size: 1.25rem;
  }
}

.quest-playbook-card {
  display: flex;
  flex-direction: column;
  width: 172px;
  height: 96px;
  padding: 8px;
  align-items: flex-start;
  justify-content: space-between;
  // margin-right: 32px;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  // display: flex;
  // flex-direction: column;
  // width: 172px;
  // height: 96px;
  // padding: 8px;
  // align-items: flex-start;
  // justify-content: space-between;
  // gap: 8px;
  // border-radius: $border-radius-8;
  // background: #FFF;
  // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  .quest-playbook-title {
    display: -webkit-box;
    max-width: 150px;
    -webkit-line-clamp: 2;
    font-size: 14px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .quest-action-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
  }
}

.quest-light-bg-border-radius {
  background-color: #dcf1ff;
  border-radius: 4px;
}

.header-separator {
  width: 1px;
  height: 36px;
  color: #c4c4c4;
}

.quest-complete-pop-up {
  width: 550px;
}
