@import '/src/styles/variables.scss';
.course-progress-sidebar {
  .close-icon {
    // margin-right: -80px;
    position: absolute;
    top: 12px;
    left: 12px;
    color: black;
    width: fit-content;
    cursor: pointer;
  }
  .pro-sidebar {
    margin-top: -95px;
  }
  .pro-sidebar.toggled {
    margin: 0;
    width: 50% !important;
    margin-top: -95px;
  }
  .pro-sidebar-header {
    border-bottom: none !important;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
  .pro-sidebar-content {
    border-bottom: none !important;
    padding: 0rem 0.5rem 0.75rem 0.5rem;
  }
  .pro-sidebar-inner {
    background: #fff;
    direction: ltr;
  }
  .pro-icon-wrapper {
    background-color: transparent !important;
  }
  .title {
    color: $base-success;
  }
  .chapters {
    padding: 1rem;
    border-radius: 5px;
    .chapter {
      border-bottom: 1px solid #dbdcde;
      padding: 0.5rem;
      margin: 0.5rem 0;
      font-size: 14px;
      cursor: pointer;
      &.active {
        background-color: #eafffb;
        color: $primary;
        border-bottom: 0px solid #dbdcde;
      }
      .progress-icon {
        height: 18px;

        .material-icons {
          font-size: 24px;
        }
        &.blank {
          color: $base-hint;
        }
        &.completed {
          color: $primary;
        }
        &.current {
          color: $primary;
        }
      }

      img {
        height: 20px;
        width: 20px;
      }
    }
  }

  .progress-bar-container {
    margin: 1rem;
    margin-bottom: 0;
    background: #f8f8f8;
    border-radius: 5px;
    .percentage {
      color: $primary;
      font-weight: 800;
    }
    .line-progress {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      background: transparent;
      border: none;
      .bar {
        width: 100%;
      }
    }
  }
}
