@import '/src/styles/variables.scss';
.course-progress-card {
  .card-header {
    padding: 0;
    border-bottom: none;
    img {
      height: 350px;
      width: 100%;
    }
  }
  .card-body {
    .title {
      color: $base-success;
    }
    .chapters {
      padding: 1rem;
      border-radius: 5px;
      .chapter {
        border-bottom: 1px solid #dbdcde;
        padding: 0.5rem;
        margin: 0.5rem 0;
        font-size: 14px;
        cursor: pointer;
        &.active {
          background-color: #e6f5ff;
          color: $primary;
          border-bottom: 0px solid #dbdcde;
        }
        .progress-icon {
          height: 18px;

          .material-icons {
            font-size: 24px;
          }
          &.blank {
            color: $base-hint;
          }
          &.completed {
            color: $base-success;
          }
          &.current {
            color: $base-success;
          }
        }

        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .progress-bar-container {
      margin: 1rem;
      margin-bottom: 0;
      background: #f8f8f8;
      border-radius: 5px;
      .percentage {
        color: $base-success;
        font-weight: 800;
      }
      .line-progress {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        background: transparent;
        border: none;
        .bar {
          width: 100%;
        }
      }
    }
  }
}
