@import '/src/styles/variables.scss';

.header {
  @media screen and (max-width: $screen-sm-min) {
    background-color: #fff;
  }
  $profile-img-dims: 30px;
  .profile-img {
    width: $profile-img-dims;
    height: $profile-img-dims;
    object-fit: cover;
  }

  #profile-dropdown {
    background: transparent;
    border: none;
    color: $base-label !important;
    font-size: 16px;
    &:focus {
      box-shadow: none;
    }
    .initials {
      $dimension: 30px;
      display: inline-block;
      background-color: $base-success;
      border-radius: 50%;
      width: $dimension;
      height: $dimension;
      color: white;
      line-height: $dimension;
    }
  }
  .user-dropdown {
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: $primary;
    }
  }
  .menu-toggle-btn {
    color: $primary;
    display: inline-block;
    @media screen and (min-width: $screen-sm-min) {
      display: none;
    }
  }
  .coach-upgrade-banner{
    height: 58px;
    border-radius: 5px;
    color: $base-dark;
    background-color: #D5F3D2;
    a{
      color : #056C75;  
      cursor: pointer;
    }
  }
}
