@import '/src/styles/variables.scss';

.invite-contact-popup {
  .modal-header {
    border: 0;
    .modal-title {
      font-family: 'Noto Sans', sans-serif !important;
      font-weight: 600;
    }
  }
  .modal-body {
    min-height: 80vh;
  }
  .search-input-container {
    max-width: 350px;
    .after-icon-button {
      border: none;
      background: transparent;
      padding: 0;
    }
  }
  .search-input {
    background: transparent;
    &:active,
    &:focus,
    &:hover {
      background: transparent;
    }
  }
}
