@import "../../../../styles/variables.scss";

#quest-score-breakdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: auto;

  .quest-score-breakdown-header-tab {
    border-bottom: 2px solid #c4c4c4;

    &:hover {
      margin-top: 10px;
      border-bottom: 2px solid $base-success;
    }

    &-active {
      font-weight: bold;
      border-bottom: 3px solid $base-success;
    }
  }

  #quest-week-list-div {
    background-color: #f4f4f4 !important;
    height: 41px !important;
    max-width: 200px !important;

    .standard-select {
      width: 100% !important;
    }
    .standard-select__control {
      border: none !important;
    }
  }
}

#insight-action-div {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // padding: 8px 16px 8px 16px;

  gap: 8px;

  .insight-action-data {
    min-width: 462px;
    border-radius: 4px;
  }
}
