@import "/src/styles/variables.scss";
@import "/src/styles/misc.scss";

$border-color: #dbdcde;
$recom-font-color: #011542;
$recom-bg: #f3f3f3;

$grey: #dbdbdb;

$li-top: 40px;
$li-bottom: 50px;

.recommendations-card {
  background: #fff;
  font-family: "Noto Sans";

  .recharts-cartesian-axis-tick-value {
    tspan {
      fill: black;
      font: bold 14px "Noto Sans", sans-serif;
    }
  }

  
  .status_approve {
    background: #3ecf78;
    border-radius: 100px;
    color: #fff;
    font-weight: 400;
  }
  .status_awaiting_approval {
    background: #ff8a34;
    border-radius: 100px;
    color: #fff;
    font-weight: 400;
  }
  .status_rejected {
    background: #e74b4b;
    border-radius: 100px;
    font-weight: 400;
    color: #fff;
  }
  .tabs {
    border-bottom: 1px solid $border-color;
    background-color: #f7f7f7;
  }

  .card-body {
    .question-text {
      @include unselectable();
      font-size: 18px;
      font-weight: 600;
    }

    .chart-container {
      max-width: 1000px;
    }

    .recom-text {
      @include unselectable();
      padding: 1rem;
      border-radius: 0.35rem;
      font-size: 15px;
      background-color: $recom-bg;
      color: $recom-font-color;
      text-align: justify;
      font-weight: 500;
    }

    .icon {
      cursor: pointer;
      position: relative;
      color: $grey;

      &.active {
        color: $base-success;
      }

      .sharp-bottom {
        &.show {
          display: inherit;
        }

        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid $base-success;
        left: 35%;
        bottom: -1px;
      }

      .bottom-line {
        position: absolute;
        display: none;
        top: 40px;
        left: calc(50% - 1px);

        &.active {
          display: flex;
          flex-direction: column;
        }

        .line {
          width: 3px;
          height: $li-bottom - 20px;
          background-color: $base-success;
        }

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          transform: translate(-30%, -30%);
          background-color: $base-success;
        }
      }
    }

    .timeline-container {
      width: 100% !important;
      // @media screen and (max-width: 767px) {
      //   width: 768px !important;
      //   overflow-x: auto;
      //   overflow-y: visible;
      // }
    }

    .timeline {
      list-style: none;
      display: inline-flex;
      justify-content: space-between;
      position: relative;
      margin: $li-top 0 $li-bottom 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 1;

        * {
          cursor: pointer;
        }

        // flex: 1 1 200px;
        &.active {
          .top-text {
            color: $base-success;
          }

          .bottom-text {
            color: $base-success;
          }

          .icon {
            color: $base-success;
          }
        }

        &::after {
          content: "";
          width: 80%;
          height: 2px;
          position: absolute;
          margin-top: -6px;
          margin-left: 100%;
          background-color: $base-success;
        }

        &.grey::after {
          background-color: $grey !important;
        }

        &:last-child::after {
          width: 0;
          height: 0;
        }
      }

      @mixin li-text {
        position: absolute;
        font-size: 14px;
        background-color: white;
        text-align: center;
        color: #adadad;
      }

      .top-text {
        @include li-text();
        top: calc($li-top - 10px) * -1;
        font-weight: 600;
      }

      .bottom-text {
        @include li-text();
        bottom: calc($li-bottom - 20px) * -1;
        font-weight: 600;
      }
    }

    .bar-container {
      padding: 1rem;
      border-radius: 0.35rem;

      // background-color: $recom-bg;
      .bar-tooltip {
        background-color: $recom-font-color;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        color: white;
        max-width: 400px;
        font-size: 13px;
        text-align: justify;
      }

      .bar-label {
        @include unselectable();
      }
    }
  }
}

.hb-light-gray {
  color: #878b9d;
}

.divider {
  border-left: 2px solid #878b9d;
}

.hr-divider {
  height: 2px;

  background: #d9d9d9;
}

.recommendation-pretext{
  a{
    color: $base-primary;
    text-decoration: none;
  }  
}
.recom-bg {
  background-color: $recom-bg !important;
  border-radius: 5px;
}