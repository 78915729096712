@import "../../../styles/variables.scss";
.support-popup {
    .modal-body{
        padding: 0;
    }
  .support-text {
    color: #1d3d92;
    font-weight: 1000;
  }
  .reach-out-area {
    background-color: $base-background;
    padding: 1rem;
    a {
      color: $base-success;
      text-decoration: none;
      font-weight: bold;
    }
    
  }
  .material-icons{
    cursor: pointer;
  }
  .bg-sky{
    background-color: #D9F0FF;  
    img{
        width: 100%;
    }
  }
}
