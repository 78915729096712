@import '/src/styles/variables.scss';

.feed {
  ul {
    list-style: none;
    padding: 0;
  }
  .feed-list {
    .feed-list-item {
      margin-bottom: 1.1rem;
      .feed-card {
        box-shadow: none !important;
        .feed-date {
          color: $primary;
          font-weight: 600;
        }
        .feed-items {
          .feed-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.75rem;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid #e9e9e9;
            &:last-of-type {
              border: none;
              margin-bottom: 0;
              padding-bottom: 0;
            }
            .feed-text {
              color: $base-label;
              font-size: 1rem;
            }
            .feed-time {
              width: 50px;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              color: $base-hint;
              font-size: 0.8rem;
              .material-icons {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
