@import "../../../styles/variables.scss";


.standard-popup ,.popup-w-600{
  margin: 1rem auto !important;

  .modal-header {
    #standard-popup-header {
      display: flex;
      justify-content: space-between;
    }

    padding: 24px;
    width: 100%;
  }

  #standard-popup-header-image {
    height: 52px;
    min-width: 52px;
    border: 1px solid $border-grey;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #btn-div {
    .standard-button {
      height: 43px;
    }
  }
}

.popup-w-600{
  max-width: 600px !important;
}