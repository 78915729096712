@import '/src/styles/variables.scss';

.profile-update-popup {
  .modal-header {
    border: none;
    padding: 2rem 2rem 1rem 2rem;
    .modal-title {
      font-family: 'Noto Sans', sans-serif !important;
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 1rem 2rem 2rem 2rem;
  }
  .email-input-with-password-link {
    label {
      width: 100%;
    }
    .change-password-link {
      color: $primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  $profile-img-dims: 100px;
  .profile-img {
    width: $profile-img-dims;
    height: $profile-img-dims;
    object-fit: cover;
  }
  .profile-img-outline {
    border-radius: 50%;
    width: $profile-img-dims + 20;
    height: $profile-img-dims + 20;
    background: transparent;
    border: 3px solid #eeeeee;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .upload-photo {
    input[type='file'] {
      display: none;
    }
    cursor: pointer;
    color: $primary;
    text-decoration: underline;
    font-size: 1rem;
  }
}
