@import '/src/styles/variables.scss';
.standard-otp {
  input::placeholder {
    font-size: 12px;
  }
  .error-text {
    font-size: 0.75rem;
    color: $base-danger;
  }
}
