@import "../../../styles/variables.scss";

.standard-table {
  th {
    color: $base-primary;
    font-weight: 1000;
  }
  tbody {
    border-top-color: #efefef !important;
    border-top-width: 3px !important;
    td {
      border-bottom-color: #e9e9e9 !important;
    }
  }
}
