@import "/src/styles/variables.scss";
@import "/src/styles/misc.scss";

.resource-card {
  color: #000000;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 5px $base-hint;
    transform: scale(1.03);
  }
  // .card {
  //   background: transparent;
  // }
  .card-header {
    color: white;
    background-color: #666666;
    text-align: center;
    padding: 1rem;
    border: none;
    font-size: 1.25rem;
    font-weight: 600;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .resource-title {
    font-size: 1rem;
  }
  .card-footer {
    border: none;
    color: $base-hint;
  }
}
