// h1,
// p {
//   font-family: Lato;
// }
@import "../../../styles/variables.scss";

.progress-circle-indicator.green svg circle:first-child {
  stroke: #069b06;
  opacity: 0.1;
}

.RCP {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.progress-circle-indicator.green svg circle:nth-child(2) {
  stroke: #069b06;
}

.progress-circle-indicator.red svg circle:first-child {
  stroke: #d9070a;
  opacity: 0.1;
}

.progress-circle-indicator.red svg circle:nth-child(2) {
  stroke: #d9070a;
}

.progress-circle-indicator.orange svg circle:first-child {
  stroke: #fe5504;
  opacity: 0.1;
}

.progress-circle-indicator.orange svg circle:nth-child(2) {
  stroke: #fe5504;
}

svg text {
  .text-black {
    fill: black;
  }
  .light-text {
    fill: #656565;
  }
  font-style: normal;
  font-weight: 600;
}
