@import '/src/styles/variables.scss';

.coach-details {
  .terms-conditions {
    color: #7b88a5;
    a {
      text-decoration: none;
      color: $primary;
      cursor: pointer;
      font-weight: 600;
    }
  }
}
