.floating-button
{
    display: flex;
    align-items: center;
    background-color: rgb(29, 61, 146);
    position: fixed;
    border-radius: 20px;
    padding:4px 12px;
    color: rgb(255, 255, 255);
    font-size: 15px;
    bottom: 60px;
    right: 32px;
    cursor: pointer;

    img{
        margin-right:8px;
    }
}

#userguiding-launcher{
    display: none;
}