@import '/src/styles/variables.scss';
.course-card {
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 5px $base-hint;
    transform: scale(1.03);
  }

  .card-header {
    padding: 0;
    border-bottom: none;
    height: 200px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    .category {
      color: #fff;
      font-weight: 800;
      padding: 4px 12px;
      border-radius: 18px;
      height: 28px;
      margin-top: 0.5rem;
      margin-right: 0.5rem;
      font-size: 14px;
    }
  }
  .card-body {
    .title {
      color: $base-success;
    }
    .info {
      border-top: 1px solid #dbdcde;
      border-bottom: 1px solid #dbdcde;
      padding: 1rem 0;
      margin-top: 1rem;
      .author {
        color: $base-border;
        font-size: 14px;
        .icon-in-btn {
          color: $base-hint;
          top: 6px;
        }
      }
    }
    .overview {
      overflow: hidden;
      height: 72px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: $base-label;
    }
  }
  .card-footer {
    border-top: none;
    padding-top: 0px;
    padding-bottom: 1rem;
    a {
      text-decoration: none !important;
    }
    .standard-button {
      height: 45px;
      padding: 12px;
      .icon-in-btn {
        margin-top: -6px;
        top: 7px;
      }
    }
    .completed {
      height: 45px;
      background-color: #f3f3f3;
      color: $base-success;
      font-weight: bold;
      cursor: pointer;
    }
    .line-progress {
      width: 100%;
      height: 45px;
      background-color: #f3f3f3;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      margin-top: 0px !important;
      .bar {
        width: 60%;
      }
      .text {
        width: 25%;
        display: inline-block;
        font-size: 12px;
        color: $base-hint;
        font-weight: 400;
      }
      .percentage {
        color: $base-success;
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 0.25rem;
      }
    }
  }
}
