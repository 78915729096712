@import '/src/styles/variables.scss';

.contact-name {
  font-weight: 600;
  color: $primary;
}
.contact-email {
  font-style: italic;
  text-decoration: underline;
  color: #888888;
}
.material-icons {
  &.send-icon {
    font-size: inherit;
  }
}
.text-light-grey {
  color: #888888;
}
