@import '/src/styles/variables.scss';

.footer {
  color: $base-border;
  padding: 0.5rem 3rem;
  border-top: 1px solid #e0e0e0;
  .terms-conditions {
    text-decoration: none;
    color: $primary;
    cursor: pointer;
    font-weight: 600;
  }
}
