@import '/src/styles/variables.scss';
.study-course {
  max-width: $screen-xxl-max;
  .chapter-navigation {
    background: #f7f7f7;
    padding: 1rem;
    button {
      padding: 0.75rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      .icon-in-btn {
        top: 2px;
      }
    }
  }
  .progress-sidebar {
    width: 100% !important;
    button {
      width: 100% !important;
      background-color: #fff;
      color: #2d4aa3;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      border: none;
      .icon-in-btn {
        top: 2px;
        &.stick {
          margin-left: -14px;
        }
      }
    }
  }  
  .breadcrumb {
    color: $base-label;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    a {
      color: $base-label;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
    div {
      &:last-child {
        color: $base-border;
      }
    }
  }
}
