@import "../../../../styles/variables.scss";

.academy-home {
  max-width: $screen-xxl-max;
  // .search-box {
  //   width: 240px;
  // }
  .search-icon {
    color: $base-border;
    cursor: pointer;
  }
  .filter-item {
    width: auto;
    @media screen and (max-width: $screen-md-min) {
      width: 100% !important;
    }
  }
  .blocked{
    height: 0px;
    opacity: 0.4;
  }
  .upgrade-area{
    z-index: 1000;
    margin-top: 100px;
    // width: 450px;
    img{
      height: 100px;
    }
    button{
      width: 200px;
    }
    
  }
}
