@import '/src/styles/variables.scss';

$contact-column-width: 200px;
$contact-header-height: 110px;
$contact-margin-right: 36px;

.associated-contacts {
  .search-input-container {
    max-width: 300px;
    .after-icon-button {
      border: none;
      background: transparent;
      padding: 0;
    }
  }
  .search-input {
    background: transparent;
    &:active,
    &:focus,
    &:hover {
      background: transparent;
    }
  }
  .filter-text {
    color: $base-border;
  }
  .filter-select {
    min-width: 150px;
  }
  .need-review-banner {
    position: absolute;
    width: 40%;
    top: -6%;
    right: -4%;
  }
  .contacts-container-fixed {
    // max-width: 1200px;
    // @media screen and (max-width: 1201px) {
    //   max-width: 900px;
    // }
    overflow: auto;
    .contacts-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .contact-column {
        flex: 1;
        min-width: $contact-column-width;
        margin-right: $contact-margin-right;

        &:last-of-type {
          margin-right: 0 !important;
        }
        .contact-header {
          height: $contact-header-height;
          color: $base-success;
          font-weight: 500;
          border-radius: 5px;
          border-color: #fff;
          &.xray-submitted-header {
            background-color: $base-success;
            border-color: $base-success;
            color: white;
          }
          .header-text {
            font-size: 14px;
          }
        }
        .contact {
          border-radius: 5px;
          border-color: white;
          transition: all 200ms ease-in-out;
          $hover-color: #d1d1d1;
          &.clickable:hover {
            background-color: rgba($hover-color, 0.3);
            border-color: rgba($hover-color, 1);
          }
          &.clickable:focus {
            background-color: rgba($hover-color, 0.6);
            border-color: rgba($hover-color, 1);
          }
          .contact-card {
            padding: 12px;
            white-space: nowrap;
            .ellipsis-text {
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .contact-company {
              color: $base-label;
              font-weight: 700;
            }
            .contact-info {
              font-size: 14px;
              .seperator {
                border-left: 2px solid $base-border;
              }
              .contact-date {
                color: $base-border;
              }
              .contact-name {
                color: $base-success;
                font-weight: 400;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
// .associated-contacts {
//   max-width: 1200px;
//   .contacts-container-fixed {
//     .contacts-container {
//       flex-wrap: nowrap;
//       overflow-x: auto;
//       .contact-column {
//         width: $contact-column-width;
//         margin-right: $contact-margin-right;
//         &:first-of-type {
//           margin-left: auto !important;
//         }
//         &:last-of-type {
//           margin-right: auto !important;
//         }
//         .contact-header {
//           height: $contact-header-height;
//           &.xray-submitted-header {
//             background-color: #2d4aa3;
//             color: white;
//           }
//         }
//       }
//     }
//   }
// }

.upgrade-plan-popup{
  iframe{
    height: calc(100vh - 150px);
  }
}