@import "../../../../styles/variables.scss";

.quest-dashboard-table {
  th {
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0 !important;
  }

  tbody {
    border-top-color: transparent !important;
    overflow: scroll;
  }

  .quest-score {
    width: 5%;
    text-align: center;
    padding: 0.5rem;
  }

  td {
    // padding: 10px !important;
    border-bottom: transparent !important;
    padding: 5px 0px !important;
  }

  th,
  td {
    max-width: 82px !important;
    &.highlight-column {
      background-color: #dcf1ff;
      border-radius: 4px;
    }
  }
}
