@import "/src/styles/variables.scss";

.progress-card {
  .modal-header,
  .modal-footer {
    border: none;
  }
  .modal-title {
    font-family: "Noto Sans", sans-serif !important;
    font-weight: 600;
  }
  .alt-primary {
    color: $primary;
  }
  .progress {
    height: 8px;
    width: 100px;
    &.in-progress {
      .progress-bar {
        background: #5faff9;
      }
    }
    &.complete {
      .progress-bar {
        background: #6ccb83;
      }
    }
  }
  table {
    tbody {
      border-top: 2px solid #efefef !important;
      tr {
        border-bottom: 1px solid #e9e9e9 !important;
      }
    }

    th {
      color: $base-success;
    }
    td {
      height: 40px;
    }
  }
  .team-member-list {
    list-style: none;
    .field-label {
      color: $base-border;
    }
    li {
      margin-bottom: 1rem;
      border-bottom: 1px solid #e9e9e9;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.discipline-card {
  background: #f8f8f8;
  border: 1px solid #dbdcde;

  .discipline-text {
    font-size: 18px;
    color: #18123f;
    font-weight: bold;
  }
}

.discipline-content {
  border-top: 0px;
  border-right: 1px;
  border-bottom: 1px;
  border-left: 1px;
  border-color: #dbdcde;
  border-style: solid;
  border-radius: 0px 0px 5px 5px;
}
.border-all-radius {
  border-radius: 5px;
}
.no-bottom-radius {
  border-radius: 5px 5px 0px 0px;
}
