@import '/src/styles/variables.scss';
.explore-card {
  .recharts-cartesian-axis-tick-value {
    tspan {
      fill: #7b88a5 !important;
      font: bold 16px 'Noto Sans', sans-serif;
    }
  }
  background: #fff;
  .number {
    background: #2d4aa3;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    padding-top: 6px;
  }

  .compare-chart-container {
    height: 140px;
    &.d-chart {
      .recharts-bar-rectangle path {
        fill: #2d4aa3;
      }
      .recharts-bar-rectangle:first-child path {
        fill: none;
        stroke: #646464;
        stroke-width: 1px;
        stroke-dasharray: 2, 2;
        stroke-linejoin: round;
      }
      .recharts-bar-rectangle:last-child path {
        fill: $primary;
      }
      .recharts-text {
        tspan {
          fill: white;
        }
      }
      .recharts-text:first-child {
        tspan {
          fill: grey;
        }
      }
    }

    &.discipline-detail-chart {
      height: 80px;
      .recharts-bar-rectangle:last-child path {
        fill: #2d4aa3;
      }
      .recharts-bar-rectangle:first-child path {
        fill: none;
        stroke: #646464;
        stroke-width: 1px;
        stroke-dasharray: 2, 2;
        stroke-linejoin: round;
      }
      .recharts-text {
        tspan {
          fill: white;
        }
      }
      .recharts-text:first-child {
        tspan {
          fill: grey;
        }
      }
    }

    &.sd-chart {
      height: 40px;
      .recharts-bar-rectangle path {
        fill: #4fb8ff;
      }
      .recharts-text {
        tspan {
          fill: white;
        }
      }
    }
    &.ssd-chart {
      height: 40px;
      .recharts-bar-rectangle path {
        fill: #85ceff;
      }
      .recharts-text {
        tspan {
          fill: white;
        }
      }
    }
    &.pra-chart {
      height: 40px;
      .recharts-bar-rectangle path {
        fill: #aedeff;
      }
      .recharts-text {
        tspan {
          fill: white;
        }
      }
    }
  }
  .bottom-bordered {
    border-bottom: 1px solid #dbdcde;
  }
  .sd-detail {
    .cs-sub-heading {
      font-size: 16px;
    }
  }
}
