@import "../../../styles/variables.scss";

#standard-tooltip {
  display: flex;
  max-width: 318px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .tooltip-title {
    // font-family: Noto Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }

  .tooltip-information {
    // font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  .tooltip-inner {
    background-color: #fff !important;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px $base-primary;
    padding: 16px;
    gap: 10px;
  }
}