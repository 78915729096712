@import "/src/styles/variables.scss";
@import "/src/styles/misc.scss";

$border-color: #dbdcde;
$recom-font-color: #011542;
$recom-bg: #f3f3f3;

$grey: #dbdbdb;

$li-top: 40px;
$li-bottom: 50px;

.initiative-wrapper {
  @include unselectable();
  padding: 1rem;
  border-radius: 0.35rem;
  font-size: 15px;
  background-color: $recom-bg;
  color: $recom-font-color;
  text-align: justify;
  font-weight: 500;

  .ini-stepper-container {
    width: 100% !important;
    position: relative;

    .init-stepper {
      list-style: none;
      display: inline-flex;
      justify-content: space-between;
      position: relative;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        position: relative;
        &::after {
          content: "";
          width: 80%;
          height: 2px;
          position: absolute;
          margin-top: -6px;
          margin-left: 100%;
          background-color: $base-success;
        }
        &.grey::after {
          background-color: $grey !important;
        }
        &:last-child::after {
          width: 0;
          height: 0;
        }
      }
      @mixin li-text {
        position: absolute;
        font-size: 14px;
        text-align: center;
      }
      .top-text {
        @include li-text();
        top: calc($li-top - 10px) * -1;
        font-weight: 600;
        color: #adadad;
        &.active-text {
          color: black !important;
          font-weight: 700 !important;
        }
      }
      .bottom-text {
        @include li-text();
        bottom: calc($li-bottom - 10px) * -1;
        font-weight: 600;
      }
      .line-wrapper {
        height: 2px;
      }
      .gray-circle {
        border-radius: 50%;
        border: 3px solid #878b9d;
      }
      .blue-circle {
        border: 3px solid #056C75;
      }
    }
  }
}
