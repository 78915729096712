@import '/src/styles/variables.scss';
$sidebar-bg:#FCFCFC;

.coach-panel {
  background-color: #e5e5e5;

  .pro-sidebar-header {
    border-bottom: none !important;
    background-color: $sidebar-bg;
  }
  .pro-icon-wrapper {
    background-color: transparent !important;
  }

  .pro-sidebar-content {
    display: flex;
    flex-direction: column;
    background-color: $sidebar-bg !important;
  }

  .guide-btn {
    &.standard-button {
      padding: 8px;
      background: #4484ff;
      border: 1px solid #4484ff;

      &:not(:disabled):not(.disabled) {
        &:hover {
          color: #4484ff;
          background-color: rgba(#fff, 0.1);
          border: 1px solid #4484ff;
        }

        &:active {
          color: #fff;
          background-color: rgba(#fff, 0.1);
          border-color: #4484ff;
        }

        &:focus {
          background-color: rgba(#fff, 0.1);
        }
      }
    }
  }

  .cs-icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;

    &.dashboard {
      background-image: url('/assets/images/icon_dashboard.svg');
    }

    &.xray {
      background-image: url('/assets/images/icon_xray.svg');
    }

    &.contacts {
      background-image: url('/assets/images/icon_contacts.svg');
    }

    &.academy {
      background-image: url('../../assets/images/icon_academy.svg');
    }

    &.support {
      background-image: url('../../assets/images/icon_support.svg');
    }
  }

  .pro-menu-item {
    .pro-inner-item {
      color: $base-hint !important;
      font-weight: 600 !important;
      border: 4px solid $sidebar-bg;
      
    }

    &.active>.pro-inner-item,
    .pro-inner-item:hover {
      color: $primary important;
      background-color: $sidebar-bg;
      .pro-icon {
        .cs-icon {
          &.dashboard {
            background-image: url('/assets/images/icon_dashboard_active.svg');
          }

          &.xray {
            background-image: url('/assets/images/icon_xray_active.svg');
          }

          &.contacts {
            background-image: url('/assets/images/icon_contacts_active.svg');
          }

          &.academy {
            background-image: url('../../assets/images/icon_academy_active.svg');
          }

          &.support {
            background-image: url('../../assets/images/icon_support_active.svg');
          }
        }
      }

      a {
        color: $primary !important;
      }
    }

    &.active>.pro-inner-item {
      border-left: 4px solid $primary;
    }

    &.disabled {
      .pro-inner-item {
        cursor: default;
        color: lightgrey !important;

        .pro-icon {
          .cs-icon {
            &.xray {
              background-image: url('/assets/images/icon_xray_disabled.svg');
            }
          }
        }
      }

      .pro-inner-item:hover {
        cursor: default;
        color: lightgrey !important;

        .pro-icon {
          .cs-icon {
            &.xray {
              background-image: url('/assets/images/icon_xray_disabled.svg');
            }
          }
        }
      }
    }
  }

  // .header {
  //   height: 80px;
  // }

  .content-container {
    height: 100% !important;
    max-width: 100%;

    @media screen and (max-width: 1101px) {
      max-width: 1100px;
    }

    overflow-y: auto;
  }
}