@import '/src/styles/variables.scss';

.divergence-card {
  table {
    th {
      background: #f8f8f8;
      color: $base-success;
      font-weight: bold;
    }
    border: 1px solid#E9E9E9;
    tr,
    th,
    td {
      border: 1px solid#E9E9E9;
      .check {
        color: $dark-grey;
      }
      .clear {
        color: #ff5555;
      }
      vertical-align: middle;
    }
    tbody {
      border-top: none !important;
    }
    tr {
      td:first-child {
        color: $dark-grey;
        font-weight: bold;
      }
    }
  }
}
