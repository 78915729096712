@import '/src/styles/variables.scss';


#select-box-div {
  #select-box-border {
    border-radius: 4px;
    max-height: 37px;
    min-width: 200px;
    max-width: 310px;
    border: 1px solid #e6e4e5;
    background: white;
    padding: 8px;
    width: fit-content;

    .label-week-separator {
      width: 1px;
      height: 16px;
      margin-left: 10px;
      background: #e6e4e5;
    }

    .standard-select-border-0__control {
      border: none;
    }

    .standard-select-border-0__menu {
      width: 310px !important;
    }

    .standard-select-border-0__single-value {
      font-size: 14px;
    }

    .select-box {
      .standard-select-border-0__control {
        width: 100%;
      }
    }
  }

  .error-border {
    border: 1px solid $base-danger !important;
  }
}
.standard-select {
  text-align: left;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    color: $base-success;
  }
  .css-319lph-ValueContainer {
    padding-left: 0;
  }
  .css-14el2xx-placeholder {
    margin-left: 0;
  }
  .select-box {
    border-bottom: $base-border;
  }
  input {
    ::placeholder {
      color: $base-hint;
    }
  }

  .error-text {
    font-size: 0.75rem;
    color: $base-danger;
  }

  .select-box-outlined {
    // border: 1px solid $border-grey;

    .standard-select__control {
      border: 1px solid #e6e4e5;
      border-radius: 4px;
      padding-left: 3px;
      background-color: transparent;
      height: 42px;
      width: 150px;
    }

    .standard-select__single-value {
      color: #f6f6f6 !important;
    }

    .standard-select__indicator {
      color: #e6e4e5;
    }
  }
}



.standard-select-border-0__menu-portal {
  z-index: 9999 !important;
}

.standard-select-border-0__menu {
  max-height: 150px !important;

  .standard-select-border-0__menu-list {
    max-height: 150px !important;
  }
}