@import '/src/styles/variables.scss';

.add-contact {
  .standard-input {
    input {
      background: transparent;
    }
  }
  .modal-header,
  .modal-footer {
    border: none;
  }
  .modal-title {
    font-family: 'Noto Sans', sans-serif !important;
    font-weight: 600;
  }
  .original-mail {
    color: $base-hint;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0.25rem;
    text-align: right;
  }
  .field-card {
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 5px;
    &.xray-journey {
      padding: 0;
      background-color: #FFF;
      border: 1px solid #08B3C3;
      .field {
        padding: 1rem;
        &.journey-field {
          background-color: #fff;
          .label {
            color: $base-border;
            margin-left: 0.9rem;
            position: relative;
            &::before {
              content: '';
              margin-right: 1.9rem;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: $base-success;
            }
            &::after {
              content: '';
              position: absolute;
              left: 4px;
              width: 2px;
              height: 200%;
              top: -170%;
              background-color: $base-success;
            }
          }
        }
        .label {
          color: $base-label;
          .label-img {
            background-color: $base-success;
            span {
              color: white;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
  .field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .label {
      color: $base-border;
      display: flex;
      justify-content: start;
      align-items: center;
      .label-img {
        position: relative;
        border-radius: 50%;
        background-color: rgba($primary, 0.15);
        margin-right: 0.8rem;
        width: 40px;
        height: 40px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40%;
        }
      }
    }
    .value {
      display: flex;
      justify-content: start;
      align-items: center;
      font-weight: 600;
      .material-icons {
        color: $primary;
      }
    }
  }
  .invite-contact-confirmation {
    .contact-name {
      font-weight: 600;
      color: $primary;
    }
    .contact-email {
      font-weight: 600;
    }
  }
  .material-icons {
    &.send-icon {
      font-size: inherit;
    }
  }
  .copied-tooltip {
    top: -130%;
    left: -50%;
    display: none;
    background-color: $base-dark;
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 14px;
    &.show {
      display: inherit;
    }
    &::after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid $base-dark;
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
