@import '/src/styles/variables.scss';

.standard-score {
  text-align: left;
  .number-box {
    display: inline-block;
    background: #e5ebf9;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    text-align: center;
    color: $base-border;
    font-size: 16px;
    padding-top: 4px;
    cursor: pointer;
    margin-top: 16px;
    &.active {
      color: #fff;
      background: $primary;
    }
    &:last-of-type {
      margin-right: 0px;
    }
  }
}
